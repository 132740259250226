import React, { useContext, useEffect } from "react";
import { useStoryblokState, StoryblokComponent } from "@storyblok/react";

import { ShopifyCollectionDAO } from "@services/ShopifyCollectionDAO";
import { ShopifyCollectionService } from "@services/ShopifyCollectionService";
import { ShopContext } from "@context/shopContext";
import { getSeoMetadata, getStoryComponent } from "@utils/helpers";
import { GenericPageSEO } from "@services/Seo/generic-page-seo";
import storyblokInstance from "@services/storyblok-service";
import { NavWrapper } from "@components/Global/Nav/NavWrapper/NavWrapper";
import { LinkVariant } from "@components/UI";

const shopifyCollectionService = new ShopifyCollectionService();

const NotFound = ({
  pageStories,
  cartAddonsCollection,
  cartSettings,
  globalStoryData
}) => {
  pageStories = useStoryblokState(pageStories, {
    resolveRelations: [
      "featured-products.products",
      "featured-collections.collections_list"
    ]
  });
  const { setCartAddonsList, setCartDiscountsSetups } = useContext(ShopContext);
  const {
    content: { global, global_footer }
  } = globalStoryData;
  const {
    content: { seo }
  } = pageStories;
  const seoData = getSeoMetadata(seo);

  useEffect(() => {
    setCartAddonsList(cartAddonsCollection);
    const cartSetups = getStoryComponent(cartSettings, "cart_setups");
    setCartDiscountsSetups(cartSetups);
  }, []);

  return (
    <>
      <GenericPageSEO
        url={`https://goodlaundry.com/not-found`}
        title={seoData.title || "Not Found"}
        description={seoData.description || "Not Found"}
      />
      <NavWrapper>
        {global?.map(blok => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))}
      </NavWrapper>
      <section className="relative min-h-[90vh] flex-col items-center justify-center py-3 px-5 text-center md:flex md:py-10">
        <img
          className="absolute left-0 top-0 -z-10 h-full w-full object-cover object-right"
          src="/404-banner.jpg"
          alt=""
        />
        <h1 className="sofia-pro-b mb-0 text-[128px] leading-[1] text-white md:text-[260px] md:leading-[1.1]">
          404
        </h1>
        <h2 className="sofia-pro-b  -mb-1 text-[32px] text-white md:text-[44px]">
          Hey good lookin’
        </h2>
        <p className="sofia-pro-r  mb-6 text-[28px] text-white md:mb-5 md:text-[36px]">
          Are you lost?
        </p>

        <LinkVariant
          linkType={"buttonLink"}
          href={`/`}
          variant={"blue"}
          className="sofia-pro-m mx-auto inline-flex w-full max-w-[400px] justify-center rounded-8 bg-[#2589BD] py-2 px-6 text-center text-18 text-white transition-all hover:bg-dark sm:w-[240px]"
        >
          Shop Good Laundry →
        </LinkVariant>
      </section>
      {global_footer?.map(blok => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </>
  );
};

export const getStaticProps = async ({ preview = false }) => {
  const { data: pageData } = await storyblokInstance.getStory({
    requestString: "cdn/stories/not-found",
    resolveRelations: [
      "featured-products.products",
      "featured-collections.collections_list"
    ]
  });
  const globalStoryData = await storyblokInstance.getStory({
    requestString: "cdn/stories/global",
    resolveRelations: ["featured-collections.collection_items"]
  });
  const {
    data: {
      story: {
        content: {
          cart_settings: [
            {
              collection_items: [
                {
                  content: { collection_id: cartAddonsCollectionId }
                }
              ]
            }
          ],
          cart_settings
        }
      },
      story
    }
  } = globalStoryData;
  const cartAddonsCollection = await shopifyCollectionService.getCollectionById(
    cartAddonsCollectionId
  );
  return {
    props: {
      preview,
      pageStories: pageData ? pageData.story : false,
      cartAddonsCollection: new ShopifyCollectionDAO(cartAddonsCollection)
        .productsList,
      cartSettings: cart_settings,
      globalStoryData: story
    }
  };
};
export default NotFound;
